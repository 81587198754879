<template>
  <div>
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center subtitle-4 black--text">
          الاشعارات
        </h1>
        <v-row class="mt-5">
          <v-col md="3" xs="12">
            <v-btn tile color="primary" @click="addDailog.open = true">
              اضافة اشعار
              <v-icon right style="font-size: 14.999994px">
                fa-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col
            md="4"
            xs="12"
          >
            <div class="d-flex flex-row">
            </div>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="table.headers"
              loading-text="جاري التحميل ... الرجاء الانتظار"
              :items="table.teacherData"
              :options.sync="tableOptions"
              :server-items-length="table.totalTeacherData"
              :loading="table.loading"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100],
              }"
            >
              <template slot="item.notification_id" slot-scope="props">
                {{ (tableOptions.page - 1) * tableOptions.itemsPerPage + props.index + 1 }}
              </template>
              <template v-slot:item.notification_type="{ item }">
                <span v-if="item.notification_type === 'general'">عام</span>
                <span v-else-if="item.notification_type === 'forType'"> {{ item.notification_for_customer_jop }}</span>
                <span v-else>خاص</span>
              </template>
              <template v-slot:item.notification_img="{ item }">
                <img
                  v-if="item.notification_img"
                  class="teacher_image_table"
                  :src="$store.state.FileUrl + item.notification_img"
                  alt
                  width="50"
                  height="50"
                  @click="showImage(item.notification_img)"
                />
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="#FF5252" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                      fa-trash
                    </v-icon>
                  </template>
                  <span>حذف</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- image Teacher dialog -->
    <v-dialog v-model="table.showImageDailog" max-width="599.999994px">
      <v-card>
        <v-card-title>
          <span class="headline">عرض الصورة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-row class="justify-center">
                <img :src="this.$store.state.FileUrl + table.imageUrlForShow" alt width="300" height="200" />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="table.showImageDailog = false">
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->

    <!-- addDialog -->
    <v-dialog v-model="addDailog.open" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDailog.isFormValid">
              <v-row>
                <!-- notification_title -->
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="addData.notification_title"
                    label="العنوان"
                    outlined
                    :rules="Rules.title"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <!-- notification_body -->
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="addData.notification_body"
                    label="المحتوى"
                    outlined
                    :rules="Rules.body"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <!-- notification_img -->
                <v-col cols="12">
                  <div class="d-flex justify-space-around align-center">
                    <v-btn text color="primary" @click="$refs.img_addUpload.click()">
                      اضافة الصورة
                    </v-btn>
                    <input v-show="false" id="img_add" ref="img_addUpload" type="file" @change="changeAddImg($event)" />
                    <div class="image-container">
                      <img
                        v-show="addData.IsImgShowing"
                        :src="addData.selectedImg"
                        :lazy-src="addData.imgUrl"
                        alt
                        width="60"
                        height="60"
                      />
                    </div>
                  </div>
                </v-col>
                <!-- notification_type -->
                <v-col md="6" cols="12">
                  <v-select
                    v-model="addData.notification_type"
                    :items="notification_typeItems"
                    :rules="Rules.type"
                    dense
                    item-text="text"
                    item-value="value"
                    label="النوع"
                    outlined
                    @change="changeCustomerTypeAdd()"
                  ></v-select>
                </v-col>

                <!-- notification_for_customer -->
                <v-col v-show="isCustomerSelectShowing" md="6" cols="12">
                  <v-autocomplete
                    v-model="addData.notification_for_customer"
                    :loading="customerSelectLoading"
                    :items="customersItems"
                    item-text="customer_name"
                    item-value="customer_id"
                    clearable
                    outlined
                    dense
                    label="الزبون"
                    @click:clear="addData.notification_for_customer = null"
                  ></v-autocomplete>
                </v-col>

                <!-- notification_customer_jop -->
                <v-col v-show="isCustomerJopSelectShowing" md="6" cols="12">
                  <v-autocomplete
                    v-model="addData.notification_for_customer_jop"
                    :loading="customerJopSelect.loading"
                    :items="customerJopSelect.data"
                    item-text="customer_jop_name"
                    clearable
                    outlined
                    dense
                    label="نوع مهنة الزبون"
                    @click:clear="addData.notification_for_customer_jop = null"
                  ></v-autocomplete>
                </v-col>

                <!-- notification_price -->
                <!-- <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="addData.notification_price"
                    label="السعر"
                    outlined
                    dense
                    required
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col> -->
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDailog.open = false">
            الغاء
          </v-btn>
          <v-btn color="primary" :loading="addDailog.loading" :disabled="!addDailog.isFormValid" @click="submitAdd">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- addDialog -->

    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500.000004px">
      <v-card>
        <v-card-title class="headline justify-center">
          هل انت متأكد من حذف هذا الاشعار ؟
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false">
            الغاء
          </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm">
            حذف
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->

    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500.000004px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center">
          {{ dialogData.bodyText }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false">
            تم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      editDailog: {
        loading: false,
        isFormValid: false,
        open: false,
      },

      addDailog: {
        loading: false,
        isFormValid: false,
        open: false,
      },

      isCustomerSelectShowing: false,

      isCustomerJopSelectShowing: false,

      notification_typeItems: [
        { text: 'عام', value: 'general' },
        { text: 'خاص', value: 'specific' },
        { text: 'لمهنة معينة', value: 'forType' },
      ],

      deleteItemLoading: false,

      editedItem: {},

      Rules: {
        title: [value => !!value || 'العنوان مطلوب'],
        body: [value => !!value || 'المحتوى مطلوب'],
        type: [value => !!value || 'النوع مطلوب'],
      },

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      customersItems: [],

      addData: {
        IsImgShowing: false,
        selectedImg: null,
        notification_title: null,
        notification_body: null,
        notification_type: null,
        notification_for_customer: null,
        notification_for_customer_jop: null,
        notification_price: null,
      },

      deletedItem: {},

      tableOptions: {},

      table: {
        showImageDailog: false,
        imageUrlForShow: null,
        search: null,
        totalTeacherData: 0,
        teacherData: [],
        loading: true,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'notification_id',
          },
          {
            text: 'العنوان',
            sortable: false,
            value: 'notification_title',
          },
          {
            text: 'المحتوى',
            sortable: false,
            value: 'notification_body',
          },
          {
            text: 'النوع',
            sortable: false,
            value: 'notification_type',
          },
          {
            text: 'السعر',
            sortable: false,
            value: 'notification_price',
          },
          {
            text: 'الصورة',
            sortable: false,
            value: 'notification_img',
          },
          {
            text: 'مستلم الاشعار',
            sortable: false,
            value: 'customer_name',
          },
          {
            text: 'التاريخ',
            sortable: false,
            value: 'notification_created_at',
          },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },

      customerJopSelect: {
        loading: false,
        data: [],
      },

      customerSelectLoading: false,
    }
  },
  watch: {
    tableOptions: {
      handler () {
        this.getDataAxios()
      },
      deep: true,
    },
  },

  created () {
    this.getCustomerData()
    this.getCustomerJop()
  },
  methods: {
    getDataAxios () {
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .get(`/notification/${page}/${itemsPerPage}`)
        .then(Response => {
          if (Response.data.status === 401) {
            this.$store.dispatch('submitLogout')
          } else {
            this.table.loading = false
            this.table.teacherData = Response.data.results
            this.table.totalTeacherData = Response.data.count
          }
        })
        .catch(error => {
          this.table.loading = false
          console.log('error', error)
        })
    },

    getCustomerData () {
      this.customerSelectLoading = true

      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .get('/customer/getAll')
        .then(Response => {
          this.customerSelectLoading = false
          if (Response.data.status === 401) {
            this.$store.dispatch('submitLogout')
          } else {
            this.customersItems = Response.data.results
          }
        })
        .catch(error => {
          this.customerSelectLoading = false
          console.log('error', error)
        })
    },

    getCustomerJop () {
      this.customerJopSelect.loading = true
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .get('/customerJop')
        .then(Response => {
          if (Response.data.status === 401) {
            this.$store.dispatch('submitLogout')
          } else {
            this.customerJopSelect.loading = false
            this.customerJopSelect.data = Response.data.results
          }
        })
        .catch(error => {
          this.customerJopSelect.loading = false
          console.log('error', error)
        })
    },

    deleteItem (item) {
      this.deletedItem = { ...item }
      console.log('deletedItem', this.deletedItem)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.deleteItemLoading = true
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .delete(`notification/${this.deletedItem.notification_id}`)
        .then(Response => {
          if (Response.data.error === false) {
            this.deleteItemLoading = false
            this.dialogDelete = false
            this.getDataAxios()
            this.showDialogfunction(Response.data.results, 'primary')
          } else {
            this.showDialogfunction(Response.data.results, '#FF5252')
          }
        })
        .catch(error => {
          this.deleteItemLoading = false
          this.dialogDelete = false
          this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
          console.log('error', error)
        })
    },

    async submitAdd () {
      this.addDailog.loading = true
      const imageSelected = document.querySelector('#img_add')
      const formData = new FormData()

      // if (imageSelected.value === '') {
      //   this.addDailog.loading = false

      //   // if there is no logo and no selected logo
      //   this.showDialogfunction('يجب اختيار صورة', '#FF8A80')
      // } else
      if (this.addData.notification_type === 'specific' && this.addData.notification_for_customer === null) {
        this.addDailog.loading = false
        this.showDialogfunction('عندما يكون نوع الاشعار خاص يجب اختيار زبون', '#FF8A80')
      } else if (this.addData.notification_type === 'forType' && this.addData.notification_for_customer_jop === null) {
        this.addDailog.loading = false
        this.showDialogfunction('عندما يكون نوع الاشعار لمهنة معينة يجب اختيار المهنة', '#FF8A80')
      } else {
        this.formAppendAdd(formData, imageSelected.files[0])
        this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

        this.axios
          .post('notification', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(Response => {
            this.addDailog.loading = false
            if (Response.data.error === false) {
              this.addDailog.open = false
              this.resetValueFunction()
              this.showDialogfunction(Response.data.results, 'primary')
              this.getDataAxios()
            } else {
              this.showDialogfunction(Response.data.results, '#FF8A80ed')
            }
          })
          .catch(error => {
            this.addDailog.loading = false
            this.showDialogfunction('حدث خطأ يرجى المحاولة مجددا', '#FF8A80')
            console.log('error', error)
          })
      }
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    changeCustomerTypeAdd () {
      if (this.addData.notification_type === 'specific') {
        this.isCustomerSelectShowing = true
        this.isCustomerJopSelectShowing = false
      } else if (this.addData.notification_type === 'forType') {
        this.isCustomerSelectShowing = false
        this.isCustomerJopSelectShowing = true
      } else {
        this.isCustomerSelectShowing = false
        this.isCustomerJopSelectShowing = false
      }
    },

    changeAddImg (event) {
      this.addData.IsImgShowing = true

      const file = event.target.files[0]
      this.addData.selectedImg = null
      if (typeof file !== 'undefined' && file !== null) {
        const fileExt = file.name.split('.').pop()
        if (
          fileExt.toLowerCase() === 'jpg' ||
          fileExt.toLowerCase() === 'jpeg' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'gif'
        ) {
          this.addData.selectedImg = URL.createObjectURL(file)
        } else {
          console.log('error_image_add')
        }
      } else {
        this.addData.selectedImg = null
      }
    },

    formAppendAdd (formData, image) {
      formData.append('title', this.addData.notification_title)
      formData.append('body', this.addData.notification_body)
      formData.append('price', this.addData.notification_price)
      formData.append('type', this.addData.notification_type)
      formData.append('customer', this.addData.notification_for_customer)
      formData.append('customerJop', this.addData.notification_for_customer_jop)
      formData.append('notification_img', image)
    },

    isNumber (evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    resetValueFunction () {
      this.addData.notification_title = null
      this.addData.notification_body = null
      this.addData.notification_type = null
      this.addData.notification_price = null
      this.addData.IsImgShowing = false
      this.addData.selectedImg = null
      this.isCustomerSelectShowing = false
      this.addData.notification_for_customer = null
      this.isCustomerJopSelectShowing = false
      this.addData.notification_for_customer_jop = null
    },

    showImage (image) {
      this.table.showImageDailog = true
      this.table.imageUrlForShow = image
    },
  },
}
</script>

<style scoped>
.teacher_image_table {
  cursor: pointer;
}
a {
  text-decoration: none;
}
.search-btn {
  width: 48.999996px;
  height: 39.999996px;
  padding: 10.000008px;
  background: #2196f3;
  font-size: 16.999992px;
  border: 1.000008px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7.000002px;
}
table.v-table tbody td {
  color: black;
}
</style>
